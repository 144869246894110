import { Component } from 'react'
import finvu from '../../assets/finvu.png';


export default class Header extends Component {
    constructor(props) {
      super(props)
    
      this.state = {}
    }
    render() {
        return (
            <nav className="navbar header-color fixed-top py-3" style={{backgroundColor: this.props.customDetails?.secondaryColor}}>
                <div className="container">
                    <div className="navbar-brand d-flex justify-content-between w-100 align-items-center">
                       
                        <div className='d-flex'>
                            {
                                this.props.customDetails?.logo?
                                <img src={this.props.customDetails?.logo} className='bank-logo' alt='logo' />:null
                            }
                           
                        </div>
                        <div className="d-flex">
                            <img src={finvu} className='finvu-logo float-end' height={'50px'} alt='logo'/>
                        </div>
                    </div>
                </div>
            </nav>
        )
    }
}