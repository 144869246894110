import { Component } from 'react';
import AuthApis from '../hepler/authApis.service';
import { toast } from 'material-react-toastify';
import { postMessage } from '../utils/common.utils';
import Sheet from 'react-modal-sheet';
import Terms from './Terms';
import Expiry from './Expiry';

export default class Login extends Component {
	constructor(props) {
		super(props);
		const queryParams = new URLSearchParams(window.location.search);
		this.ecreq = queryParams.get('ecreq');
		this.reqdate = queryParams.get('reqdate');
		this.fi = queryParams.get('fi')
			? queryParams.get('fi').split(' ').join('+')
			: null;
		console.log(this.fi, 'fi');
		this.fip = queryParams.get('fip')
			? window.atob(queryParams.get('fip'))
			: null;
		this.requestorType = queryParams.get('requestorType')
			? queryParams.get('requestorType')
			: null;
		global.requestorType = queryParams.get('requestorType')
			? queryParams.get('requestorType')
			: null;
		global.isAddAccount = queryParams.get('isAddAccount') ? true : false;
		this.state = {
			time: 60,
			OTP: null,
			isResend: true,
			isOpenSheet: false,
			isDisable: false,
			disableType: '',
			isExpired: false,
		};
		this.number = null;
		this.authApis = new AuthApis();
		console.log('this.fip - ', this.fip);
	}
	componentDidMount() {
		if (!this.props?.location?.state?.fromTerm) {
			this.getFiuInfo();
		}
		console.log(
			'window.navigator.appVersion - ',
			window.navigator.appVersion.indexOf('Mac') !== -1
		);
	}
	componentDidUpdate(prevProps) {
		if (prevProps.location.pathname !== this.props.location.pathname) {
			if (this.props.history.action === 'POP') {
				window.confirm(
					'Are you sure to go back ? Consent will not be actioned.'
				);
			}
		}
	}
	initiate = () => {
		// if (this.state.time !== 0) {
		this.setState((prevState, prevProps) => ({
			time: prevState.time - 1,
		}));
		if (this.state.time === 0) {
			clearInterval(this.id);
			this.setState({ time: 60, isResend: true });
		}
		// }
	};
	getFiuInfo = () => {
		this.props.updateInitialLoader(true);
		this.authApis
			.getFiuInfo(this.ecreq, this.reqdate, this.fi, this.requestorType)
			.then((res) => {
				console.log('res getFiuInfo - ', res);
				if (res?.payload?.status === 'SUCCESS') {
					let entityId = res.payload.entityInfo.entityId;
					global.institutionType = res.payload.institutionType;
					global.lspFips = res.payload.fips;
					if (entityId) {
						// this.sendOtp()
						this.getConfigInfo(entityId);
					} else {
						this.props.updateInitialLoader(false);
						// this.props.history.push(`/error`)
						toast.error(res);
						if (res.status === 'FAILURE') {
							postMessage('session', 'FAILURE');
						} else if (res.status === 'ERROR') {
							postMessage('session', 'ERROR');
						}
					}
				} else {
					toast.error(res.message.message);
					this.setState({ isExpired: true });
					this.props.updateInitialLoader(false);
				}
			});
	};
	getConfigInfo = (entityId) => {
		this.authApis.fiuSdkConfig(entityId).then((res) => {
			console.log('res', res);
			this.sendOtp();
			if (res?.status === 'SUCCESS') {
				if (res?.entityConfig?.themeConfig) {
					let entityConfig = res?.entityConfig?.themeConfig;
					entityConfig.excludeFIP = res?.entityConfig.excludeFIP
						? res?.entityConfig.excludeFIP
						: [];
					entityConfig.sdkOptions = res?.entityConfig?.sdkOptions
						? res?.entityConfig.sdkOptions
						: {};
					console.log('entityConfig - ', entityConfig);
					this.props.updateEntityID(entityConfig);
				}
				global.sdkOptions = res?.entityConfig?.sdkOptions;
				global.isRedirect = res?.entityConfig?.sdkOptions?.redirect;
			} else {
				console.log(res);
				this.props.updateInitialLoader(false);
			}
		});
	};
	// Send OTP
	sendOtp = () => {
		this.getOtp();
		this.setState({ isDisable: true, disableType: 'Resend' });
		this.authApis.userLogin().then((res) => {
			console.log('res', res);
			this.setState({ isDisable: false });
			this.props.updateInitialLoader(false);
			if (res.status === 'SEND') {
				console.log(res.message);
			} else {
				toast.error(res.message);
				console.log(res.message);
				// this.props.history.push(`/error`)
				if (res.status === 'FAILURE') {
					postMessage('session', 'FAILURE');
				} else if (res.status === 'ERROR') {
					postMessage('session', 'ERROR');
				}
			}
		});
	};
	verifyOtp = (e) => {
		e.preventDefault();
		// this.props.updateLoader(true)
		this.setState({ isDisable: true, disableType: 'Verify' });
		this.authApis.verifyOtp(this.state.OTP).then((res) => {
			console.log('res', res);
			if (res?.status && res?.status === 'SUCCESS') {
				this.getUserdetails();
				// this.props.history.push(`/accounts/${global.mobileNumber}/${this.props.match.params?.fid?this.props.match.params.fid:this.state.fid}`)
			} else {
				toast.error(res);
				console.log(res);
				clearInterval(this.id);
				this.setState({ time: 60, isResend: true });
				// this.props.updateLoader(false)
				this.setState({ isDisable: false });
				// this.props.history.push(`/error`)
				if (res.status === 'FAILURE') {
					postMessage('session', 'FAILURE');
				} else if (res.status === 'ERROR') {
					postMessage('session', 'ERROR');
				}
			}
		});
	};
	getUserdetails = () => {
		this.authApis.getUserDetails().then((res) => {
			console.log('res', res);
			if (res?.status && res?.status === 'ACCEPT') {
				console.log(res.message);
				global.mobileNumber = res.UserInfo.mobileNo;
				global.userId = res.UserInfo.userId;
				global.mobileNumberArray.push(res.UserInfo.mobileNo);
				this.populerFips(res.UserInfo.mobileNo);
			} else {
				toast.error(res);
				console.log(res);
				this.setState({ isDisable: false });
				// this.props.updateLoader(false)
				// this.props.history.push(`/error`)
				if (res.status === 'FAILURE') {
					postMessage('session', 'FAILURE');
				} else if (res.status === 'ERROR') {
					postMessage('session', 'ERROR');
				}
			}
		});
	};
	/*otp toggle*/
	getOtp = () => {
		this.setState({ isResend: false, OTP: '' });
		this.id = setInterval(this.initiate, 1000);
	};
	checkOtpValid = () => {
		if (this.state.OTP?.length === 6 || this.state.OTP?.length === 8) {
			return false;
		}
		return true;
	};
	populerFips = (number) => {
		this.authApis.popularFipList().then((res) => {
			console.log('res', res);
			// this.props.updateLoader(false)
			this.setState({ isDisable: true });
			if (res?.status === 'SUCCESS') {
				console.log('res?.poularSearch - ', res?.poularSearch);
				global.populerFips = res?.poularSearch;
				let objectFormatFip = {};
				for (let i = 0; i < res?.poularSearch.length; i++) {
					objectFormatFip[res?.poularSearch[i].fipId] = res?.poularSearch[i];
				}
				global.objectFormatFip = objectFormatFip;
				console.log('objectFormatFip - ', objectFormatFip);
				if (this.fip) {
					this.props.history.push(
						`/accounts/${number}/${this.fip}?isInitial=${true}`
					);
				} else {
					this.props.history.push(`/accounts/${number}`);
				}
			} else {
				toast.error(res);
				console.log(res);
				// this.props.history.push(`/error`)
				if (res.status === 'FAILURE') {
					postMessage('session', 'FAILURE');
				} else if (res.status === 'ERROR') {
					postMessage('session', 'ERROR');
				}
			}
		});
	};
	render() {
		return this.state.isExpired ? (
			<Expiry />
		) : (
			<div className="login-page mt-9">
				<div className="container px-3">
					<div className="row" style={{ minHeight: '68vh' }}>
						<div className="col-12 mt-5">
							<div className="row ">
								<div className="col-lg-12 col-12">
									<h1
										className="fs-20 fw-600 text-start"
										style={{
											color: this.props.customDetails?.headingColor,
											fontFamily: this.props.customDetails?.fontFamily,
										}}
									>
										VERIFY MOBILE
									</h1>
									<p
										className="fs-14 fw-500 text-start"
										style={{
											color: this.props.customDetails?.textColor,
											fontFamily: this.props.customDetails?.fontFamily,
										}}
									>
										OTP sent to your registered mobile number
										<br />
										<span
											className="fs-16 fw-600"
											style={{
												color: this.props.customDetails?.subHeadingColor,
												fontFamily: this.props.customDetails?.fontFamily,
											}}
										>
											{' '}
											{this.props.match.params.number}{' '}
										</span>
									</p>
								</div>
								<div className="col-lg-5 col-md-6 col-12 mb-5">
									<div className="input-group mb-3" style={{ height: '50px' }}>
										<input
											type="number"
											inputMode="decimal"
											value={this.state.OTP}
											onChange={(e) => {
												this.setState({ OTP: e.target.value });
											}}
											onFocus={(e) => {
												e.target.addEventListener(
													'wheel',
													function (e) {
														e.preventDefault();
													},
													{ passive: false }
												);

												e.target.addEventListener('keydown', function (event) {
													const keysToPrevent = [
														'PageUp',
														'PageDown',
														'ArrowUp',
														'ArrowDown',
													];
													if (keysToPrevent.includes(event.key)) {
														// Prevent the default behavior
														event.preventDefault();
													}
												});
											}}
											className="form-control ps-3"
											placeholder="Enter OTP"
											aria-label="number"
											aria-describedby="basic-addon1"
											style={{
												fontFamily: this.props.customDetails?.fontFamily,
											}}
										/>
									</div>
									<div className="d-flex justify-content-between align-items-center">
										<button
											className="fs-14 fw-600 link-btn"
											disabled={!this.state.isResend || this.state.isDisable}
											onClick={this.sendOtp}
											style={{
												color:
													this.state.isResend || this.state.isDisable
														? this.props.customDetails?.subHeadingColor
														: 'grey',
											}}
										>
											Resend OTP
										</button>
										{this.state.time !== 60 ? (
											<p
												className="fs-14 fw-600 text-success my-auto"
												style={{
													fontFamily: this.props.customDetails?.fontFamily,
												}}
											>
												in {this.state.time} secs
											</p>
										) : null}
									</div>
								</div>
								{window.navigator.appVersion.indexOf('Mac') !== -1 ? (
									<div className="col-12">
										<p className="text-lg-start text-center">
											Please DO NOT switch app while reading the OTP
										</p>
									</div>
								) : null}

								<div className="col-lg-3 col-md-6 col-12 d-flex justify-content-center">
									<button
										type="button"
										disabled={
											!(
												this.state.OTP?.length === 6 ||
												this.state.OTP?.length === 8
											) || this.state.isDisable
										}
										onClick={(e) => {
											this.verifyOtp(e);
										}}
										className="btn float-end px-5 fs-16 fw-600 text-white"
										style={{
											height: this.props.customDetails?.btnHeight,
											width: this.props.customDetails?.btnWidth,
											borderRadius: this.props.customDetails?.borderRadius,
											backgroundColor: this.props.customDetails?.primaryColor,
											fontFamily: this.props.customDetails?.fontFamily,
										}}
									>
										{this.state.isDisable &&
										this.state.disableType === 'Verify' ? (
											<div
												className="snippet"
												style={{ display: 'flex', justifyContent: 'center' }}
												data-title=".dot-pulse"
											>
												<div className="stage">
													<div className="dot-pulse-btn"></div>
												</div>
											</div>
										) : (
											'VERIFY'
										)}
									</button>
								</div>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-6 offset-lg-3 col-12">
							<p
								className="fs-12 fw-600"
								style={{
									color: this.props.customDetails?.textColor,
									bottom: '0px',
									fontFamily: this.props.customDetails?.fontFamily,
								}}
							>
								{' '}
								By continuing, you agree to Finvu{' '}
								<span
									className=""
									style={{ color: this.props.customDetails?.subHeadingColor }}
								>
									<button
										disabled={this.state.isDisable}
										className="btn btn-link"
										onClick={() => {
											this.setState({ isOpenSheet: true });
										}}
										style={{ fontFamily: this.props.customDetails?.fontFamily }}
									>
										Terms and Conditions{' '}
									</button>
								</span>
								<br />
								<br />
								Finvu is a brand of Cookiejar Technologies Pvt Ltd, a regulated
								and licensed NBFC AA by RBI
							</p>
						</div>
					</div>
				</div>
				<Sheet
					isOpen={this.state.isOpenSheet}
					onClose={() => this.setState({ isOpenSheet: false })}
				>
					<Sheet.Container>
						<Sheet.Header>
							<div style={{ textAlign: 'right' }}>
								<button
									className="closeModal"
									onClick={() => this.setState({ isOpenSheet: false })}
								>
									X
								</button>
							</div>
						</Sheet.Header>
						<Sheet.Content>
							<Terms />
						</Sheet.Content>
					</Sheet.Container>

					<Sheet.Backdrop />
				</Sheet>
			</div>
		);
	}
}
