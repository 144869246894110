export const isDiscoveredAccountLinked = (
	discoveredAccount,
	linkedAccounts
) => {
	const { FIType, accType, maskedAccNumber } = discoveredAccount;
	return linkedAccounts.some(
		(linkedAccount) =>
			linkedAccount.FIType === FIType &&
			linkedAccount.accType === accType &&
			linkedAccount.maskedAccNumber === maskedAccNumber
	);
};
