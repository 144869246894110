// ---------------------- UAT / Dev Start----------------------

// export const FipIds = {
//     'BARB0KIMXXX': { name: 'Finvu Bank', img: 'https://finvu.in/cdn/finvu_bank_icon.png', fip_id: 'BARB0KIMXXX' },
//     'INDUSIND': { name: 'IndusInd Bank', img: 'https://finvu.in/cdn/indusindbank_logo.jpg', fip_id: 'INDUSIND' },
//     'ICICI-FIP': { name: 'ICICI Bank', img: 'https://finvu.in/cdn/icicibank_icon.jpg', fip_id: 'ICICI-FIP' },
//     'dhanagarbank': { name: 'Dhanagar Bank', img: 'https://finvu.in/cdn/finvu_bank_icon.png', fip_id: 'dhanagarbank' },
//     'IDFC-FIP': { name: 'IDFC First Bank', img: 'https://finvu.in/cdn/idfcfirstbank_logo.jpg', fip_id: 'IDFC-FIP' },
//     'HDFC-FIP': { name: 'HDFC Bank', img: 'https://finvu.in/cdn/hdfcbank_logo.jpg', fip_id: 'HDFC-FIP' },
//     'FDRLFIP': { name: 'Federal Bank', img: 'https://finvu.in/cdn/federalbank_logo.png', fip_id: 'FDRLFIP' },
//     'AXIS001': { name: 'Axis Bank', img: 'https://finvu.in/cdn/axisbank_logo.jpg', fip_id: 'AXIS001' },
//     'KOTAK-FIP': { name: 'Kotak Mahindra Bank', img: 'https://finvu.in/cdn/kotakbank_app_logo.png', fip_id: 'KOTAK-FIP' }
// }

// export const banks = [
//     { name: 'Finvu Bank', img: 'https://finvu.in/cdn/finvu_bank_icon.png', fip_id: 'BARB0KIMXXX' },
//     { name: 'IndusInd Bank', img: 'https://finvu.in/cdn/indusindbank_logo.jpg', fip_id: 'INDUSIND' },
//     { name: 'ICICI Bank', img: 'https://finvu.in/cdn/icicibank_icon.jpg', fip_id: 'ICICI-FIP' },
//     { name: 'Dhanagar Bank', img: 'https://finvu.in/cdn/finvu_bank_icon.png', fip_id: 'dhanagarbank' },
//     { name: 'IDFC First Bank', img: 'https://finvu.in/cdn/idfcfirstbank_logo.jpg', fip_id: 'IDFC-FIP' },
//     { name: 'HDFC Bank', img: 'https://finvu.in/cdn/hdfcbank_logo.jpg', fip_id: 'HDFC-FIP' },
//     { name: 'Federal Bank', img: 'https://finvu.in/cdn/federalbank_logo.png', fip_id: 'FDRLFIP' },
//     { name: 'Axis Bank', img: 'https://finvu.in/cdn/axisbank_logo.jpg', fip_id: 'AXIS001' },
//     { name: 'Kotak Mahindra Bank', img: 'https://finvu.in/cdn/kotakbank_app_logo.png', fip_id: 'KOTAK-FIP' }
// ]

// ---------------------- UAT / Dev End----------------------


// ---------------------- PROD Start----------------------
/**
export const FipIds = {
    'AXIS001': 					{ name: 'Axis Bank', 			img: 'https://finvu.in/cdn/axisbank_logo.jpg', 				fip_id: 'AXIS001' },
    'HDFC-FIP': 				{ name: 'HDFC Bank', 			img: 'https://finvu.in/cdn/hdfcbank_logo.jpg', 				fip_id: 'HDFC-FIP' },
    'ICICI-FIP': 				{ name: 'ICICI Bank', 			img: 'https://finvu.in/cdn/icicibank_icon.jpg', 			fip_id: 'ICICI-FIP' },
    'IDFCFirstBank-FIP': 		{ name: 'IDFC FIRST BANK', 		img: 'https://finvu.in/cdn/idfcfirstbank_logo.jpg', 		fip_id: 'IDFCFirstBank-FIP' },
    'KotakMahindraBank-FIP': 	{ name: 'Kotak Mahindra Bank',	img: 'https://finvu.in/cdn/kotakbank_logo.png', 			fip_id: 'KotakMahindraBank-FIP' },
    'fiplive@indusind': 		{ name: 'IndusInd Bank Ltd', 	img: 'https://finvu.in/cdn/indusindbank_logo.jpg', 			fip_id: 'fiplive@indusind' },
    'FDRLFIPPROD': 				{ name: 'FEDERAL BANK',			img: 'https://finvu.in/cdn/federalbank_logo.png', 			fip_id: 'FDRLFIPPROD' },
    'KarurVysyaBank-FIP': 		{ name: 'Karur Vysya Bank', 	img: 'https://finvu.in/cdn/finvu_bank_icon.png', 			fip_id: 'KarurVysyaBank-FIP' },
    'AUBank-FIP': 				{ name: 'AUBank', 				img: 'https://finvu.in/cdn/finvu_bank_icon.png', 			fip_id: 'AUBank-FIP' }
}

export const banks = [
    { name: 'Axis Bank', 			img: 'https://finvu.in/cdn/axisbank_logo.jpg', 				fip_id: 'AXIS001' },
    { name: 'HDFC Bank', 			img: 'https://finvu.in/cdn/hdfcbank_logo.jpg', 				fip_id: 'HDFC-FIP' },
    { name: 'ICICI Bank', 			img: 'https://finvu.in/cdn/icicibank_icon.jpg', 			fip_id: 'ICICI-FIP' },
    { name: 'IDFC FIRST BANK', 		img: 'https://finvu.in/cdn/idfcfirstbank_logo.jpg', 		fip_id: 'IDFCFirstBank-FIP' },
    { name: 'Kotak Mahindra Bank',	img: 'https://finvu.in/cdn/kotakbank_logo.png', 			fip_id: 'KotakMahindraBank-FIP' },
    { name: 'IndusInd Bank Ltd', 	img: 'https://finvu.in/cdn/indusindbank_logo.jpg', 			fip_id: 'fiplive@indusind' },
    { name: 'FEDERAL BANK',			img: 'https://finvu.in/cdn/federalbank_logo.png', 			fip_id: 'FDRLFIPPROD' },
    { name: 'Karur Vysya Bank', 	img: 'https://finvu.in/cdn/finvu_bank_icon.png', 			fip_id: 'KarurVysyaBank-FIP' },
    { name: 'AUBank', 				img: 'https://finvu.in/cdn/finvu_bank_icon.png', 			fip_id: 'AUBank-FIP' }
]
 */
// ---------------------- PROD End----------------------

export const postMessage = (action, status) => {
    var obj = { action: action, status: status, source: "finvu" };
    var myParent: Window = window.top;
    if (window.parent !== window.top) {
        myParent = window.parent;
    }
    myParent.postMessage(obj, '*');
    if (window["ReactNativeWebView"]) {
        // Post event to react native if this is running in react native.
        (window["ReactNativeWebView"]).postMessage(JSON.stringify(obj));
    } else if (window["FinvuAndroid"]) {
        // Post event if this is running in Android webview.
        (window["FinvuAndroid"]).postMessage(JSON.stringify(obj));
    } else if(window["webkit"]){
        // Post event if this is running in IOS webview.
        if((window["webkit"]).messageHandlers["FinvuIOS"]){
            ((window["webkit"]).messageHandlers["FinvuIOS"]).postMessage(JSON.stringify(obj));
        }
    }
}