import { Component } from 'react';
import AuthApis from '../hepler/authApis.service';
export default class Home extends Component {
	constructor(props) {
		super(props);
		const queryParams = new URLSearchParams(window.location.search);
		const filterFips = () => {
			let consentFitypes = [];
			if (global?.institutionType === 'LSP') {
				global.selectedLSP.forEach((fiu) =>
					consentFitypes.push(...fiu.fiTypes)
				);
			} else {
				consentFitypes = global?.consent?.fiTypes;
			}
			const filteredFips = global.populerFips.filter((x) =>
				x?.fipFitypes?.some((fi) => consentFitypes?.includes(fi))
			);

			return filteredFips;
		};
		global.isAddAccount = queryParams.get('isAddAccount') ? true : false;
		this.isNewUser = queryParams.get('isNewUser') ? true : false;
		this.state = {
			selectedNumber: global.mobileNumber
				? global.mobileNumber
				: global.mobileNumberArray[0],
		};
		this.authApis = new AuthApis();
		this.popularFIPs = [];
		if (this.isNewUser) {
			this.popularFIPs = global?.filteredFips ? global.filteredFips : [];
		} else {
			this.popularFIPs = global.populerFips?.length ? filterFips() : [];
		}
		if (queryParams.get('number')) {
			this.number = queryParams.get('number');
		} else {
			this.number = global.mobileNumber;
		}
	}
	componentDidMount() {}
	componentDidUpdate(prevProps) {
		if (prevProps.location.pathname !== this.props.location.pathname) {
			if (this.props.history.action === 'POP') {
				window.confirm(
					'Are you sure to go back ? Consent will not be actioned.'
				);
			}
		}
	}
	goToAccounts = (fid) => {
		this.props.history.push(`/accounts/${this.state.selectedNumber}/${fid}`);
	};
	render() {
		return (
			<div className="home-page mt-9">
				<div className="container">
					<div className="row">
						<div className="col-12 mb-3 px-4 mt-5">
							<h1
								className="fs-20 fw-600 text-start"
								style={{
									color: this.props.customDetails?.headingColor,
									fontFamily: this.props.customDetails?.fontFamily,
								}}
							>
								SELECT INSTITUTION
							</h1>
							<p
								className="fs-14 fw-500 text-start"
								style={{
									color: this.props.customDetails?.textColor,
									fontFamily: this.props.customDetails?.fontFamily,
								}}
							>
								Information from your account held with selected institution
								will be shared
							</p>
							<p
								className="fs-14 fw-500 text-start"
								style={{
									color: this.props.customDetails?.textColor,
									fontFamily: this.props.customDetails?.fontFamily,
								}}
							>
								<span
									style={{
										color: '#1168B4',
										fontWeight: 600,
										marginRight: '2px',
									}}
								>
									{global.mobileNumber}
								</span>
								will be used to find your accounts with the institution.
							</p>
							{this.props.customDetails?.sdkOptions?.allowDifferentMobile ===
							'Y' ? (
								<p
									className="fs-16 fw-bold text-start m-0 mb-4"
									style={{
										cursor: 'pointer',
										color: '#1168B4',
										fontFamily: this.props.customDetails?.fontFamily,
									}}
									onClick={() => {
										this.props.history.push(
											`/NoAccountFound/${global.mobileNumber}?step=${101}`
										);
									}}
								>
									USE ANOTHER NUMBER
								</p>
							) : null}

							<div className="row" style={{ textAlign: '-webkit-center' }}>
								{this.popularFIPs.map((el, i) => {
									if (this.props.customDetails?.excludeFIP) {
										if (
											!this.props.customDetails?.excludeFIP.includes(el?.fipId)
										) {
											return (
												<div
													className="col-xl-1 col-lg-2 col-md-3 col-4"
													key={i}
													onClick={() => {
														this.goToAccounts(el?.fipId);
													}}
													style={{ cursor: 'pointer' }}
												>
													<div
														className="p-2 border border-1 d-flex align-items-center justify-content-center bank-logo-block"
														style={{
															width: '75px',
															height: '75px',
															objectFit: 'contain',
														}}
													>
														<img
															src={el?.productIconUri}
															alt={`${el?.productName} logo`}
															className="p-2 bank-image"
															style={{
																width: '70px',
																height: 'auto',
																objectFit: 'contain',
															}}
														/>
													</div>
													<h1
														className="fs-14 fw-600 mt-2"
														style={{
															color: this.props.customDetails?.textColor,
															fontFamily: this.props.customDetails?.fontFamily,
														}}
													>
														{el?.productName}
													</h1>
												</div>
											);
										} else {
											return;
										}
									} else {
										return (
											<div
												className="col-xl-1 col-lg-2 col-md-3 col-4"
												key={i}
												onClick={() => {
													this.goToAccounts(el?.fipId);
												}}
												style={{ cursor: 'pointer' }}
											>
												<div
													className="p-2 border border-1 d-flex align-items-center justify-content-center bank-logo-block"
													style={{
														width: '75px',
														height: '75px',
														objectFit: 'contain',
													}}
												>
													<img
														src={el?.productIconUri}
														alt={`${el?.productName} logo`}
														className="p-2 bank-image"
														style={{
															width: '70px',
															height: 'auto',
															objectFit: 'contain',
														}}
													/>
												</div>
												<h1
													className="fs-14 fw-600 mt-2"
													style={{
														color: this.props.customDetails?.textColor,
														fontFamily: this.props.customDetails?.fontFamily,
													}}
												>
													{el?.productName}
												</h1>
											</div>
										);
									}
								})}
							</div>
						</div>
						<div className="d-flex justify-content-center my-3">
							{global.isAddAccount ? (
								<button
									type="button"
									style={{ fontFamily: this.props.customDetails?.fontFamily }}
									className="btn btn-lg fs-14 w-100 fw-600 bleu-text-color my-3"
									onClick={() => this.props.history.push(`/payment-consent`)}
								>
									BACK
								</button>
							) : null}
						</div>
					</div>
				</div>
			</div>
		);
	}
}
