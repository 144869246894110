import { Component } from 'react';
import AuthApis from '../hepler/authApis.service';
import { toast } from 'material-react-toastify';
import { postMessage } from '../utils/common.utils';
// import confirm from '../assets/confirm.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-regular-svg-icons';

export default class Consent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			show: true,
			linkedAccounts: [],
			selectedAccounts: [],
			consentDetails: null,
			isDisable: false,
			disableType: '',
		};
		this.authApis = new AuthApis();
	}

	componentDidMount() {
		console.log('global.selectedLSP - ', global.selectedLSP);
		if (this.props?.location?.state?.data?.length) {
			this.setState({
				linkedAccounts: this.props?.location?.state?.data,
				selectedAccounts: [...this.props?.location?.state?.data],
			});
			this.props.updateLoader(true);
			this.consentRequestDetails();
		} else {
			this.props.updateLoader(true);
			setTimeout(() => {
				this.getLinkedAcc();
			}, 100);
		}
	}
	componentDidUpdate(prevProps) {
		if (prevProps.location.pathname !== this.props.location.pathname) {
			if (this.props.history.action === 'POP') {
				window.confirm(
					'Are you sure to go back ? Consent will not be actioned.'
				);
			}
		}
	}
	getLinkedAcc = () => {
		this.authApis.getLinkedAccounts().then((res) => {
			console.log('res', res);

			if (res?.status === 'SUCCESS') {
				this.setState({
					linkedAccounts: res.LinkedAccounts,
					selectedAccounts: [...res.LinkedAccounts],
				});
				this.consentRequestDetails(res?.status);
			} else {
				this.consentRequestDetails(res?.status);
				console.log(res?.message);
			}
		});
	};
	consentRequestDetails = (status) => {
		if (!this.state.consentDetails) {
			this.authApis.consentRequestDetails().then((res) => {
				console.log('res', res);
				this.props.updateLoader(false);
				if (res.status === 'SUCCESS') {
					this.setState({ consentDetails: res });
					global.consent = res;
					const filterFips = () => {
						console.log('global.consent from filterFips: ', global.consent);
						let consentFitypes = [];
						if (global?.institutionType === 'LSP') {
							global.selectedLSP.forEach((fiu) =>
								consentFitypes.push(...fiu.fiTypes)
							);
						} else {
							consentFitypes = global?.consent?.fiTypes;
						}
						console.log('consentFiTypes: ', consentFitypes);
						const filteredFips = global.populerFips.filter((x) =>
							x?.fipFitypes?.some((fi) => consentFitypes.includes(fi))
						);

						return filteredFips;
					};
					global.filteredFips = filterFips();
          if (status === 'RECORD-NOT-FOUND') {
            setTimeout(() => {
              this.props.history.push(
                `/home/?number=${
                  global.mobileNumber
                }&isAddAccount=${true}&isNewUser=${true}`
              );
            }, 100);
          }
				} else {
					toast.error(res.message);
					console.log(res.message);
					// this.props.history.push(`/error`)
					if (res.status === 'FAILURE') {
						postMessage('session', 'FAILURE');
					} else if (res.status === 'ERROR') {
						postMessage('session', 'ERROR');
					}
					setTimeout(() => {
						this.props.history.push({
							pathname: `/consent-status`,
							state: {
								statusCode: 403,
							},
						});
					}, 3000);
				}
			});
		}
	};
	consentApproveRequest = async (status) => {
		this.setState({ isDisable: true, disableType: status });
		let result = [];
		let fips = await this.state.selectedAccounts.map((el) => {
			return el?.fipId;
		});
		fips = [...new Set(fips)];
		for (let j = 0; j < fips?.length; j++) {
			let withFip = {
				FIP: {
					id: fips[j],
				},
				Accounts: [],
			};
			for (let i = 0; i < this.state.selectedAccounts?.length; i++) {
				if (fips[j] === this.state.selectedAccounts[i]?.fipId) {
					let single = {
						linkRefNumber: this.state.selectedAccounts[i]?.linkRefNumber,
						accType: this.state.selectedAccounts[i]?.accType,
						accRefNumber: this.state.selectedAccounts[i]?.accRefNumber,
						maskedAccNumber: this.state.selectedAccounts[i]?.maskedAccNumber,
						FIType: this.state.selectedAccounts[i]?.FIType,
						fipId: this.state.selectedAccounts[i]?.fipId,
						fipName: this.state.selectedAccounts[i]?.fipName,
					};
					withFip.Accounts.push(single);
				}
			}
			result.push(withFip);
		}
		if (global?.institutionType !== 'LSP') {
			this.authApis
				.consentApproveRequest(result, status, null, null)
				.then((res) => {
					console.log('res', res);
					this.setState({ isDisable: false, disableType: '' });
					if (res && res?.status === 'SUCCESS') {
						this.props.history.push({
							pathname: `/consent-status`,
							state: {
								status:
									status === 'ACCEPT' ? 'Consent approved' : 'Consent denied',
								statusCode: status === 'ACCEPT' ? 200 : 403,
								fiuName: this.state.consentDetails.FIU.name
									? this.state.consentDetails.FIU.name
									: '',
							},
						});
					} else {
						toast.error(res.message);
						console.log(res.message);
						if (res.status === 'FAILURE') {
							postMessage('session', 'FAILURE');
						} else if (res.status === 'ERROR') {
							postMessage('session', 'ERROR');
						}
						this.props.history.push({
							pathname: `/consent-status`,
							state: {
								statusCode: 403,
							},
						});
					}
				});
		} else {
			for (let i = 0; i < global.selectedLSP?.length; i++) {
				await this.authApis
					.consentApproveRequest(
						result,
						status,
						global.selectedLSP[i].ConsentHandle,
						global.selectedLSP[i].FIU.id
					)
					.then((res) => {
						console.log('res indexi', i);
						this.setState({ isDisable: false, disableType: '' });
						if (res && res?.status === 'SUCCESS') {
							if (global.selectedLSP?.length - 1 === i) {
								this.props.history.push({
									pathname: `/consent-status`,
									state: {
										status:
											status === 'ACCEPT'
												? 'Consent approved'
												: 'Consent denied',
										statusCode: status === 'ACCEPT' ? 200 : 403,
										// fiuName: this.state.consentDetails.FIU.name ? this.state.consentDetails.FIU.name : ''
									},
								});
							}
						} else {
							toast.error(res.message);
							console.log(res.message);
							if (res.status === 'FAILURE') {
								postMessage('session', 'FAILURE');
							} else if (res.status === 'ERROR') {
								postMessage('session', 'ERROR');
							}
							this.props.history.push({
								pathname: `/consent-status`,
								state: {
									status: 'Consent denied',
									statusCode: 403,
								},
							});
						}
					});
			}
		}
	};
	selectUnselectAcc = (el) => {
		console.log('this.state.linkedAccounts1  - ', this.state.linkedAccounts);
		let data = this.state.selectedAccounts;
		if (this.state.selectedAccounts.includes(el)) {
			let index = data.indexOf(el);
			data.splice(index, 1);
		} else {
			data.push(el);
		}
		console.log('this.state.linkedAccounts2  - ', this.state.linkedAccounts);
		this.setState({ selectedAccounts: data });
	};
	dateFormat(dateString, showTime) {
		if (dateString) {
			let newDate = new Date(dateString);
			let dataArray = newDate.toString().split(' ');
			console.log('dataArray::', dataArray);
			if (showTime) {
				return `${dataArray[2]} ${dataArray[1]} ${dataArray[3]} ${dataArray[4]}`;
			}
			return `${dataArray[2]} ${dataArray[1]} ${dataArray[3]}`;
		}
	}
	capitalize = (s) => {
		return s && s[0].toUpperCase() + s.slice(1).toLowerCase();
	};
	render() {
		return (
			// Approval section

			<div className="account-select-page mt-9">
				<div className="container">
					{this.state.show ? (
						<div>
							<div className="row mt-3 mx-2" style={{ paddingBottom: '150px' }}>
								<div className="col-12">
									<div className="d-flex align-items-center mb-3 mt-4">
										<FontAwesomeIcon
											icon={faCircleCheck}
											style={{ color: 'green', height: '30px', width: '33px' }}
										/>
										<h1
											className="fs-20 fw-600 text-start my-0 flex-grow-1 ms-2"
											style={{
												color: this.props.customDetails?.headingColor,
												fontFamily: this.props.customDetails?.fontFamily,
											}}
										>
											CONFIRM CONSENT
										</h1>
										{global?.institutionType !== 'LSP' ? (
											<span
												onClick={() => {
													this.setState({ show: false });
												}}
												className="bleu-text-color fs-12"
												style={{
													cursor: 'pointer',
													fontFamily: this.props.customDetails?.fontFamily,
												}}
											>
												DETAILS
											</span>
										) : (
											<span
												onClick={() => {
													this.props.history.push(`/select-lender`);
												}}
												className="bleu-text-color fs-12"
												style={{
													cursor: 'pointer',
													fontFamily: this.props.customDetails?.fontFamily,
												}}
											>
												BACK
											</span>
										)}
									</div>
									{global?.institutionType !== 'LSP' ? (
										<p
											className="fs-12 fw-bold text-start"
											style={{
												color: this.props.customDetails?.textColor,
												fontFamily: this.props.customDetails?.fontFamily,
											}}
										>
											Requested on{' '}
											{this.dateFormat(
												this.state?.consentDetails?.startTime,
												false
											)}
										</p>
									) : null}

									<p
										className="fs-12 fw-600 text-start"
										style={{
											color: this.props.customDetails?.textColor,
											fontFamily: this.props.customDetails?.fontFamily,
										}}
									>
										AA handle {global?.userId}
									</p>
									{global?.institutionType !== 'LSP' ? (
										<p
											className="fs-14 fw-500 text-start"
											style={{
												color: this.props.customDetails?.textColor,
												fontFamily: this.props.customDetails?.fontFamily,
											}}
										>
											{this.state.consentDetails?.FIU?.name} requires your
											consent to access account information
										</p>
									) : (
										<p
											className="fs-14 fw-500 text-start"
											style={{
												color: this.props.customDetails?.textColor,
												fontFamily: this.props.customDetails?.fontFamily,
											}}
										>
											Below insitutions will fetch your account information from
											selected accounts
										</p>
									)}
									{global?.institutionType !== 'LSP' ? (
										<p className="fs-14 fw-bold text-start">
											<li
												style={{
													fontFamily: this.props.customDetails?.fontFamily,
												}}
											>
												From{' '}
												{this.dateFormat(
													this.state.consentDetails?.DataDateTimeRange?.from,
													false
												)}{' '}
												to{' '}
												{this.dateFormat(
													this.state.consentDetails?.DataDateTimeRange?.to,
													false
												)}
											</li>
											<li
												style={{
													fontFamily: this.props.customDetails?.fontFamily,
												}}
											>
												{this.state?.consentDetails?.fetchType === 'ONETIME'
													? 'Information fetch one time'
													: `Information fetch up to ${this.state?.consentDetails?.Frequency?.value} times a ${this.state?.consentDetails?.Frequency?.unit}`}
											</li>
										</p>
									) : (
										<div style={{ textAlign: 'left' }}>
											{global?.selectedLSP?.map((el, i) => {
												return (
													<p className="mb-0" style={{ color: '#2D9CDB' }}>
														<span
															style={{
																color: '#27AE60',
																fontSize: '20px',
																fontWeight: '700',
															}}
															className="mr-3"
														>
															✓
														</span>{' '}
														{el?.FIU?.name}
													</p>
												);
											})}
										</div>
									)}
								</div>
								<div className="col-12 pt-3">
									<p
										className="fs-20 fw-600 text-start"
										style={{
											color: this.props.customDetails?.textColor,
											fontFamily: this.props.customDetails?.fontFamily,
										}}
									>
										ACCOUNTS
									</p>
									<p
										className="fs-14 fw-500 text-start"
										style={{
											color: this.props.customDetails?.textColor,
											fontFamily: this.props.customDetails?.fontFamily,
										}}
									>
										Allow access from your linked accounts
									</p>
								</div>
								{this.state.linkedAccounts.map((el, i) => {
									return (
										<div className="col-12 mb-3" key={i}>
											<div className="row p-2 row-border">
												<div className="col-lg-2 col-3">
													<img
														src={
															global.objectFormatFip[el.fipId]?.productIconUri
														}
														alt="logo"
														style={{
															width: '45px',
															height: 'auto',
															objectFit: 'contain',
														}}
													/>
													<h1
														className="fs-10 text-center"
														style={{
															color: this.props.customDetails?.headingColor,
															fontFamily: this.props.customDetails?.fontFamily,
														}}
													>
														{el?.fipName}
													</h1>
												</div>
												<div
													className="col-lg-2 col-7"
													style={{ alignSelf: 'center' }}
												>
													<h1
														className="fs-16 fw-800 text-start"
														style={{
															color: '#000',
															fontFamily: this.props.customDetails?.fontFamily,
														}}
													>
														{el?.maskedAccNumber}
													</h1>
													<h1
														className="fs-14 fw-bold text-start"
														style={{
															color: '#2D9CDB',
															fontFamily: this.props.customDetails?.fontFamily,
														}}
													>
														{el?.accType}
													</h1>
												</div>
												<div
													className="col-lg-8 col-2"
													style={{ alignSelf: 'center' }}
												>
													<div className="form-check form-check-inline float-end">
														<span
															style={{ cursor: 'pointer' }}
															className={`form-check-input ${
																this.state.selectedAccounts.includes(el)
																	? 'selected-circle border-0'
																	: 'border-circle'
															} `}
															onClick={() => {
																this.selectUnselectAcc(el);
															}}
														></span>
													</div>
												</div>
											</div>
										</div>
									);
								})}

								<div className="col-12 pt-2">
									<div className="col-md-6">
										<p
											className="fs-16 fw-bold text-start m-0"
											style={{
												color: '#1168B4',
												fontFamily: this.props.customDetails?.fontFamily,
												cursor: 'pointer',
											}}
											onClick={() => {
												this.props.history.push(
													`/home/?number=${
														global.mobileNumber
													}&isAddAccount=${true}`
												);
											}}
										>
											+ ADD ACCOUNT
										</p>
									</div>
								</div>
							</div>
						</div>
					) : (
						<div className="row px-3 text-center">
							<div className="col-lg-8 offset-lg-2 col-12 text-center mt-5">
								<h5
									className="mb-4 fs-20 fw-600 text-start"
									style={{
										color: this.props.customDetails?.headingColor,
										fontFamily: this.props.customDetails?.fontFamily,
									}}
								>
									DETAILS OF ACCESS
								</h5>

								<div className="row">
									<div className="col-lg-6 col-md-12 col-12 mt-2 text-center">
										<div className="row">
											<div className="col-sm-4 col-md-4 col-12 fw-bold text-start m-0">
												<p
													className="fw-bold text-start m-0"
													style={{
														color: this.props.customDetails?.headingColor,
														fontFamily: this.props.customDetails?.fontFamily,
													}}
												>
													Consent requested on
												</p>
											</div>
											<div className="col-sm-12 col-md-8 col-12 ">
												<p
													className="mb-4 text-start ps-0"
													style={{
														color: this.props.customDetails?.textColor,
														fontFamily: this.props.customDetails?.fontFamily,
													}}
												>
													{this.dateFormat(
														this.state.consentDetails?.startTime,
														true
													)}
												</p>
											</div>
										</div>
									</div>

									<div className="col-lg-6 col-md-12 col-12 mt-2 text-center">
										<div className="row">
											<div className="col-sm-4 col-md-4 col-12 fw-bold text-start m-0">
												<p
													className="fw-bold text-start m-0"
													style={{
														color: this.props.customDetails?.headingColor,
														fontFamily: this.props.customDetails?.fontFamily,
													}}
												>
													Purpose
												</p>
											</div>
											<div className="col-sm-12 col-md-8 col-12 ">
												<p
													className="mb-4 text-start ps-0"
													style={{
														color: this.props.customDetails?.textColor,
														fontFamily: this.props.customDetails?.fontFamily,
													}}
												>
													{this.state.consentDetails?.Purpose?.text}
												</p>
											</div>
										</div>
									</div>

									<div className="col-lg-6 col-md-12 col-12 mt-2 text-center">
										<div className="row">
											<div className="col-sm-4 col-md-4 col-12">
												<p
													className="fw-bold text-start m-0"
													style={{
														color: this.props.customDetails?.headingColor,
														fontFamily: this.props.customDetails?.fontFamily,
													}}
												>
													{this.capitalize(
														this.state?.consentDetails?.fetchType
													)}
												</p>
											</div>
											<div className="col-sm-12 col-md-8 col-12">
												<p
													className="mb-4 text-start"
													style={{
														color: this.props.customDetails?.textColor,
														paddingLeft: 0,
														fontFamily: this.props.customDetails?.fontFamily,
													}}
												>
													{this.state?.consentDetails?.fetchType === 'ONETIME'
														? 'Information fetch one time'
														: `Information fetch up to ${this.state?.consentDetails?.Frequency?.value} times a ${this.state?.consentDetails?.Frequency?.unit}`}
												</p>
											</div>
										</div>
									</div>

									<div className="col-lg-6 col-md-12 col-6 mt-2">
										<div className="row">
											<div className="col-sm-4 col-md-4 col-12">
												<p
													className="fw-bold text-start m-0"
													style={{
														color: this.props.customDetails?.headingColor,
														fontFamily: this.props.customDetails?.fontFamily,
													}}
												>
													From Date
												</p>
											</div>
											<div className="col-sm-12 col-md-8 col-12">
												<p
													className="mb-4 text-start"
													style={{
														color: this.props.customDetails?.textColor,
														fontFamily: this.props.customDetails?.fontFamily,
													}}
												>
													{this.dateFormat(
														this.state.consentDetails?.DataDateTimeRange?.from,
														false
													)}
												</p>
											</div>
										</div>
									</div>
									<div className="col-lg-6 col-md-12 col-6 mt-2">
										<div className="row">
											<div className="col-sm-4 col-md-4 col-12">
												<p
													className="fw-bold text-start m-0"
													style={{
														color: this.props.customDetails?.headingColor,
														fontFamily: this.props.customDetails?.fontFamily,
													}}
												>
													To Date
												</p>
											</div>
											<div className="col-sm-12 col-md-8 col-12">
												<p
													className="mb-4 text-start"
													style={{
														color: this.props.customDetails?.textColor,
														fontFamily: this.props.customDetails?.fontFamily,
													}}
												>
													{this.dateFormat(
														this.state.consentDetails?.DataDateTimeRange?.to,
														false
													)}
												</p>
											</div>
										</div>
									</div>

									<div className="col-lg-6 col-md-12 col-12 mt-2">
										<div className="row">
											<div className="col-sm-4 col-md-4 col-12">
												<p
													className="fw-bold text-start m-0"
													style={{
														color: this.props.customDetails?.headingColor,
														fontFamily: this.props.customDetails?.fontFamily,
													}}
												>
													Account Information
												</p>
											</div>
											<div className="col-sm-12 col-md-8 col-12">
												<p
													className="mb-4 text-start"
													style={{
														color: this.props.customDetails?.textColor,
														fontFamily: this.props.customDetails?.fontFamily,
													}}
												>
													{this.state.consentDetails?.consentTypes.map(
														(el, i) => {
															return (
																<span className="mr-2" key={i}>
																	{this.capitalize(el).replace(/[-_]/g, ' ')}
																	{`${
																		this.state.consentDetails?.consentTypes
																			?.length -
																			1 ===
																		i
																			? ''
																			: ','
																	}`}
																</span>
															);
														}
													)}
												</p>
											</div>
										</div>
									</div>

									<div className="col-lg-6 col-md-12 col-6 mt-2">
										<div className="row">
											<div className="col-sm-4 col-md-4 col-12">
												<p
													className="fw-bold text-start m-0"
													style={{
														color: this.props.customDetails?.headingColor,
														fontFamily: this.props.customDetails?.fontFamily,
													}}
												>
													Data{' '}
													{this.capitalize(this.state.consentDetails?.mode)}
												</p>
											</div>
											<div className="col-sm-12 col-md-8 col-12">
												<p
													className="mb-4 text-start"
													style={{
														color: this.props.customDetails?.textColor,
														fontFamily: this.props.customDetails?.fontFamily,
													}}
												>
													Until {this.state.consentDetails?.DataLife?.value}{' '}
													{this.state.consentDetails?.DataLife?.unit}
												</p>
											</div>
										</div>
									</div>
									<div className="col-lg-6 col-md-12 col-6 mt-2">
										<div className="row">
											<div className="col-sm-4 col-md-4 col-12">
												<p
													className="fw-bold text-start m-0"
													style={{
														color: this.props.customDetails?.headingColor,
														fontFamily: this.props.customDetails?.fontFamily,
													}}
												>
													Consent Expiry
												</p>
											</div>
											<div className="col-sm-12 col-md-8 col-12">
												<p
													className="mb-4 text-start"
													style={{
														color: this.props.customDetails?.textColor,
														fontFamily: this.props.customDetails?.fontFamily,
													}}
												>
													{this.dateFormat(
														this.state.consentDetails?.expireTime,
														false
													)}
												</p>
											</div>
										</div>
									</div>
									<div className="col-lg-6 col-md-12 col-12 mt-2 text-center">
										<div className="row">
											<div className="col-sm-4 col-md-4 col-12 fw-bold text-start m-0">
												<p
													className="fw-bold text-start m-0"
													style={{
														color: this.props.customDetails?.headingColor,
														fontFamily: this.props.customDetails?.fontFamily,
													}}
												>
													Account Types
												</p>
											</div>
											<div className="col-sm-12 col-md-8 col-12 ">
												<p
													className="mb-4 text-start ps-0"
													style={{
														color: this.props.customDetails?.textColor,
														fontFamily: this.props.customDetails?.fontFamily,
													}}
												>
													{this.state.consentDetails?.fiTypes.map((el, i) => {
														return (
															<span className="mr-2" key={i}>
																{this.capitalize(el).replace(/[-_]/g, ' ')}
																{`${
																	this.state.consentDetails?.fiTypes?.length -
																		1 ===
																	i
																		? ''
																		: ','
																}`}
															</span>
														);
													})}
												</p>
											</div>
										</div>
									</div>

									<div className="col-lg-6 offset-lg-3 col-sm-6 mt-2">
										<button
											type="button"
											className="btn btn-lg btn-block fs-16 fw-600 text-white"
											style={{
												height: this.props.customDetails?.btnHeight,
												width: this.props.customDetails?.btnWidth,
												borderRadius: this.props.customDetails?.borderRadius,
												backgroundColor: this.props.customDetails?.primaryColor,
												fontFamily: this.props.customDetails?.fontFamily,
											}}
											onClick={() => {
												this.setState({ show: true });
											}}
										>
											BACK
										</button>
									</div>
								</div>
							</div>
						</div>
					)}

					{/* <div className='col-lg-6 offset-lg-3 col-12 px-5'>
                        <p className='fs-12 fw-600 position-absolute normal-text' style={{ bottom: '0px' }}>By continuing, you agree to Finvu <span className='bleu-text-color'>Terms and Conditions </span></p>
                    </div> */}
				</div>
				{this.state.show ? (
					<div>
						<div className="accept-row" style={{ width: '100%' }}>
							<div className="pt-4">
								<button
									type="button"
									className="btn btn-lg fs-16 fw-600 text-white"
									onClick={() => {
										this.consentApproveRequest('ACCEPT');
									}}
									disabled={
										!this.state.selectedAccounts?.length || this.state.isDisable
									}
									style={{
										height: this.props.customDetails?.btnHeight,
										width: this.props.customDetails?.btnWidth,
										borderRadius: this.props.customDetails?.borderRadius,
										backgroundColor: this.props.customDetails?.primaryColor,
										fontFamily: this.props.customDetails?.fontFamily,
									}}
								>
									{this.state.isDisable &&
									this.state.disableType === 'ACCEPT' ? (
										<div
											className="snippet"
											style={{ display: 'flex', justifyContent: 'center' }}
											data-title=".dot-pulse"
										>
											<div className="stage">
												<div className="dot-pulse-btn"></div>
											</div>
										</div>
									) : (
										'Approve'
									)}
								</button>
							</div>
							<div className="pt-2">
								<button
									type="button"
									disabled={this.state.isDisable}
									style={{ fontFamily: this.props.customDetails?.fontFamily }}
									className="btn btn-lg fs-14 w-100 fw-600 bleu-text-color"
									onClick={() => {
										this.consentApproveRequest('DENY');
									}}
								>
									{this.state.isDisable && this.state.disableType === 'DENY' ? (
										<div
											className="snippet"
											style={{ display: 'flex', justifyContent: 'center' }}
											data-title=".dot-pulse"
										>
											<div className="stage">
												<div className="dot-pulse-btn"></div>
											</div>
										</div>
									) : (
										'DENY'
									)}
								</button>
							</div>
						</div>
					</div>
				) : null}
			</div>
		);
	}
}
