import { Component } from 'react';
import AuthApis from '../hepler/authApis.service';
import { postMessage } from '../utils/common.utils';

export default class ConsentStatus extends Component {
	constructor(props) {
		super(props);
		this.state = {
			time: 5,
			isCloseWindow: false,
		};
		this.authApis = new AuthApis();
	}
	componentDidMount() {
		if (global.isRedirect === 'true') {
			this.startTimer();
		} else {
			this.logout();
		}
	}

	startTimer = () => {
		this.id = setInterval(() => {
			this.setState((prevState, prevProps) => ({
				time: prevState.time - 1,
			}));
			if (this.state.time === 0) {
				clearInterval(this.id);
				this.setState({ time: 0 });
				if (this.props?.location?.state?.statusCode === 200) {
					postMessage('consent', 'ACCEPT');
				} else {
					postMessage('consent', 'DENY');
				}
				this.redirect();
			}
		}, 1000);
	};
	logout = () => {
		this.authApis.logout(global.userId).then((res) => {
			console.log('res', res);
			if (res?.status === 'SUCCESS') {
				if (this.props?.location?.state?.statusCode === 200) {
					postMessage('consent', 'ACCEPT');
				} else {
					postMessage('consent', 'DENY');
				}
				console.log(res.message);
				this.setState({ isCloseWindow: true });
			} else {
				console.log(res.message);
			}
		});
	};
	redirect = () => {
		let code = this.props?.location?.state?.statusCode === 200 ? 0 : 1;
		this.authApis.encryptRequest(code).then((res) => {
			console.log('res errrrnnn', res);
			let payload = res?.payload;
			this.authApis.logout(global.userId).then((res) => {
				console.log('res', res);
				if (res?.status === 'SUCCESS') {
					if (this.props?.location?.state?.statusCode === 200) {
						postMessage('consent', 'ACCEPT');
					} else {
						postMessage('consent', 'DENY');
					}
					if (payload['redirectUrl'] && payload['redirectUrl'] !== 'none') {
						if (payload['redirectUrl'].includes('?')) {
							window.location.replace(
								payload['redirectUrl'] +
									'&ecres=' +
									payload['encryptedResponse'] +
									'&' +
									'resdate=' +
									payload['responseDate'] +
									'&' +
									'fi=' +
									payload['encryptedFiId']
							);
						} else {
							window.location.replace(
								payload['redirectUrl'] +
									'?ecres=' +
									payload['encryptedResponse'] +
									'&' +
									'resdate=' +
									payload['responseDate'] +
									'&' +
									'fi=' +
									payload['encryptedFiId']
							);
						}
					} else {
						this.setState({ isCloseWindow: true });
					}
					console.log(res.message);
				} else {
					console.log(res.message);
				}
			});
		});
	};
	componentDidUpdate(prevProps) {
		if (prevProps.location.pathname !== this.props.location.pathname) {
			if (this.props.history.action === 'POP') {
				window.confirm(
					'Are you sure to go back ? Consent will not be actioned.'
				);
			}
		}
	}
	render() {
		return (
			<section>
				<div className="container d-flex flex-column align-items-center">
					<div
						className="text-center"
						style={{ position: 'absolute', top: '112px' }}
					>
						{global.isRedirect && !this.state.isCloseWindow ? (
							<p
								className="text-center text-danger"
								style={{ fontFamily: this.props.customDetails?.fontFamily }}
							>
								Please DO NOT hit REFRESH or BACK
							</p>
						) : null}
					</div>
					<div className="centervs">
						<h1
							className="text-secondary my-5"
							style={{ fontFamily: this.props.customDetails?.fontFamily }}
						>
							{this.props?.location?.state?.status
								? this.props?.location?.state.status
								: 'Something went wrong'}
						</h1>
						{!global.isRedirect || this.state.isCloseWindow ? (
							<h2
								className="text-primary fw-bold my-2"
								style={{ fontFamily: this.props.customDetails?.fontFamily }}
							>
								You may close this window now.
							</h2>
						) : (
							<h2
								className="text-primary fw-bold my-2"
								style={{ fontFamily: this.props.customDetails?.fontFamily }}
							>
								You will be redirected in {this.state.time} secs
							</h2>
						)}
						{global?.institutionType !== 'LSP' ? (
							<h6
								className="text-secondary fw-bold my-2"
								style={{ fontFamily: this.props.customDetails?.fontFamily }}
							>
								{this.props?.location?.state?.fiuName
									? this.props?.location?.state.fiuName
									: 'Bank'}{' '}
								<small className="fw-normal"> has been notified</small>
							</h6>
						) : (
							<div style={{ textAlign: 'center' }}>
								<h6
									className="text-secondary fw-bold my-2"
									style={{ fontFamily: this.props.customDetails?.fontFamily }}
								>
									Below have been notified
								</h6>
								{global.selectedLSP?.map((el, i) => {
									return (
										<p className="mb-0" style={{ color: '#2D9CDB' }}>
											<span
												style={{
													color: '#27AE60',
													fontSize: '20px',
													fontWeight: '700',
												}}
												className="mr-3"
											>
												✓
											</span>{' '}
											{el?.FIU?.name}
										</p>
									);
								})}
							</div>
						)}

						<div className="text-secondary fw-bold my-3">
							<b style={{ fontFamily: this.props.customDetails?.fontFamily }}>
								{' '}
								Download FINVU app to manage consents and more!.
							</b>
						</div>
					</div>
				</div>
			</section>
		);
	}
}
