import { Component } from 'react'

export default class Error extends Component {
  render() {
    return (
      <div className="centervs">
        <h1 style={{fontFamily: this.props.customDetails?.fontFamily}}>
          Oops!</h1>
        <h2 style={{fontFamily: this.props.customDetails?.fontFamily}}>
          Something went wrong</h2>
        {/* <div className="error-details">
          Sorry, an error has occured, Requested page not found!
        </div> */}

      </div>
    )
  }
}
