import { Component } from 'react';

export default class Expiry extends Component {
	render() {
		return (
			<div className="centervs">
				<h1 style={{ fontFamily: this.props.customDetails?.fontFamily }}>
					Oops!
				</h1>
				<h2 style={{ fontFamily: this.props.customDetails?.fontFamily }}>
					This link has expired. Kindly check with your institution to get a new
					link for approving consent.
				</h2>
			</div>
		);
	}
}
