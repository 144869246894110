import { Component } from 'react'
import bankLogo from '../assets/finvu.png'
import AuthApis from '../hepler/authApis.service';
import { toast } from 'material-react-toastify';
import { postMessage } from '../utils/common.utils';

const defaultLogo = 'https://finvu.in/cdn/finvu_loan_1.png';

export default class Lenders extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedAccounts: [],
            consentDetails: null,
            consentRequestList: []
        };
        this.authApis = new AuthApis()
    }

    componentDidMount() {
        this.consentRequestDetails()
    }
    // componentDidUpdate(prevProps) {
    //     if (prevProps.location.pathname !== this.props.location.pathname) {

    //     }
    // }
    consentRequestDetails = async () => {
        this.props.updateLoader(true)
        this.authApis.consentRequestDetails().then(res => {
            console.log("res consentRequestDetails - ", res);
            if (res.status === 'SUCCESS') {
                this.getEntityInfo(res.consentRequestList)

            } else {
                toast.error(res)
                console.log("lebderr - ",res)
                this.props.updateLoader(false)
                if (res.status === "FAILURE") {
                    postMessage('session', 'FAILURE')
                } else if (res.status === "ERROR") {
                    postMessage('session', 'ERROR')
                }
                setTimeout(() => {
                    this.props.history.push({
                        pathname: `/consent-status`,
                        state: {
                            statusCode: 403,
                        }
                    });
                }, 3000)
            }
        })
    }
    getEntityInfo = async (consentRequestList) => {
        for (let i = 0; i < consentRequestList?.length; i++) {
            await this.authApis.entityInfo(consentRequestList[i].FIU.id).then(result => {
                console.log("entity Details - ", result);
                if (result.status === 'SUCCESS') {
                    consentRequestList[i].entityInfoDetails = result
                    if ((consentRequestList.length - 1) === i) {
                        let successConsents = consentRequestList.map(el => { if (el.status === "SUCCESS") { return el } })
                        global.selectedLSP = [...successConsents]
                        this.setState({ consentRequestList: [...successConsents], selectedAccounts: successConsents })
                        this.props.updateLoader(false)
                    }
                } else {
                    this.props.updateLoader(true)
                    toast.error(result.message)
                    console.log(result.message)
                    if (result.status === "FAILURE") {
                        postMessage('session', 'FAILURE')
                    } else if (result.status === "ERROR") {
                        postMessage('session', 'ERROR')

                    }
                }
            })
        }
    }
    selectAccounts = () => {
        this.props.history.push(`/payment-consent`)
    }
    selectUnselectAcc = (el) => {
        console.log("el - ", el)
        let data = this.state.selectedAccounts
        if (this.state.selectedAccounts.includes(el)) {
            let index = data.indexOf(el)
            data.splice(index, 1)
        } else {
            data.push(el)
        }
        global.selectedLSP = data
        this.setState({ selectedAccounts: data })
    }
    dateFormat(dateString, showTime) {
        if (dateString) {
            let newDate = new Date(dateString);
            let dataArray = newDate.toString().split(' ');
            if (showTime) {
                return `${dataArray[2]} ${dataArray[1]} ${dataArray[3]} ${dataArray[4]}`;
            }
            return `${dataArray[2]} ${dataArray[1]} ${dataArray[3]}`;
        }
    }
    capitalize = (s) => {
        return s && s[0].toUpperCase() + s.slice(1).toLowerCase();
    }
    render() {
        return (
            <div className="account-select-page mt-9">
                <div className="container">
                    {!this.state.consentDetails ?
                        <div>
                            <div className='row mt-3 mx-2' style={{ paddingBottom: '150px' }}>
                                <div className="col-12 mt-4">
                                    <div className='d-flex align-items-center mb-3'>
                                        <h1 className='fs-20 fw-600 text-start my-0 flex-grow-1' style={{ color: this.props.customDetails?.headingColor, fontFamily: this.props.customDetails?.fontFamily }}>CONFIRM CONSENT</h1>
                                    </div>

                                    <p className='fs-12 fw-600 text-start' style={{ color: this.props.customDetails?.textColor, fontFamily: this.props.customDetails?.fontFamily }}>AA handle  {global?.userId}</p>
                                    <p className='fs-14 fw-500 text-start' style={{ color: this.props.customDetails?.textColor, fontFamily: this.props.customDetails?.fontFamily }}>Select Institutions to share your account information</p>

                                </div>

                                {
                                    this.state.consentRequestList.map((el, i) => {
                                        if (el.status === "SUCCESS") {
                                            return (
                                                <div className="col-12 mb-3" key={i}>
                                                    <div className="row p-2 row-border" style={{ cursor: 'pointer' }}>
                                                        <div className="col-lg-12 col-12">
                                                            <div className="form-check float-end">
                                                                <span className={`form-check-input ${this.state.selectedAccounts.includes(el) ? 'selected-circle border-0' : 'border-circle'} `} onClick={() => { this.selectUnselectAcc(el) }}></span>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-12 col-12">
                                                            <div className='d-flex' style={{ alignItems: 'center', marginTop: '-24px', marginRight: '30px' }}>
                                                                <img src={el?.entityInfoDetails?.entityLogoUri ? el?.entityInfoDetails?.entityLogoUri : defaultLogo} alt="logo" style={{ width: '88px', height: 'auto', objectFit: 'contain', marginRight: '8px' }} />
                                                                <h1 className="lender-name" style={{ color: this.props.customDetails?.headingColor, fontFamily: this.props.customDetails?.fontFamily }}>{el?.FIU?.name}</h1>
                                                            </div>
                                                        </div>
                                                        <div className="col-6 mt-4">
                                                            <div className='lenders-props' style={{ textAlign: 'left', color: this.props.customDetails?.textColor, fontFamily: this.props.customDetails?.fontFamily }}>Purpose</div>
                                                            <div className='lenders-values mt-2' style={{ textAlign: 'left' }}><b>{el.Purpose?.text}</b></div>
                                                        </div>
                                                        <div className="col-6 mt-4">
                                                            <div className='lenders-props' style={{ textAlign: 'left', color: this.props.customDetails?.textColor, fontFamily: this.props.customDetails?.fontFamily }}>Consent expires on</div>
                                                            <div className='lenders-values mt-2' style={{ textAlign: 'left' }}><b>{this.dateFormat(el?.expireTime, false)}</b></div>
                                                        </div>
                                                        <div className="col-12 mt-4">
                                                            <div className='lenders-props' style={{ textAlign: 'left', color: this.props.customDetails?.textColor, fontFamily: this.props.customDetails?.fontFamily }}>Information will be fetched for period</div>
                                                            <div className='lenders-values mt-2' style={{ textAlign: 'left' }}><b>From {this.dateFormat(el?.DataDateTimeRange?.from, false)} To {this.dateFormat(el?.DataDateTimeRange?.to, false)}</b></div>
                                                        </div>
                                                        <div className="col-12 mt-4">
                                                            <div className="float-end">
                                                                <span style={{ color: "#1168B4", fontFamily: this.props.customDetails?.fontFamily }} onClick={() => { this.setState({ consentDetails: el }) }}>DETAILS</span>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            )
                                        } else {
                                            return null
                                        }

                                    })
                                }

                                <div className="col-12 pt-2">

                                </div>
                            </div>
                        </div>
                        :

                        <div className="row px-3 text-center">
                            <div className="col-lg-8 offset-lg-2 col-12 text-center mt-5">
                                <h5 className="mb-4 fs-20 fw-600 text-start" style={{ color: this.props.customDetails?.headingColor, fontFamily: this.props.customDetails?.fontFamily }}>DETAILS OF ACCESS</h5>



                                <div className='row'>
                                    <div className="col-lg-6 col-md-12 col-12 mt-2 text-center">
                                        <div className="row">
                                            <div className="col-sm-4 col-md-4 col-12 fw-bold text-start m-0"><p className='fw-bold text-start m-0' style={{ color: this.props.customDetails?.headingColor, fontFamily: this.props.customDetails?.fontFamily }}>Consent requested on</p></div>
                                            <div className="col-sm-12 col-md-8 col-12 "><p className="mb-4 text-start ps-0" style={{ color: this.props.customDetails?.textColor, fontFamily: this.props.customDetails?.fontFamily }}>{this.dateFormat(this.state.consentDetails?.startTime, true)}</p></div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-12 col-12 mt-2 text-center">
                                        <div className="row">
                                            <div className="col-sm-4 col-md-4 col-12 fw-bold text-start m-0"><p className='fw-bold text-start m-0' style={{ color: this.props.customDetails?.headingColor, fontFamily: this.props.customDetails?.fontFamily }}>Purpose</p></div>
                                            <div className="col-sm-12 col-md-8 col-12 "><p className="mb-4 text-start ps-0" style={{ color: this.props.customDetails?.textColor, fontFamily: this.props.customDetails?.fontFamily }}>{this.state.consentDetails?.Purpose?.text}</p></div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-12 col-12 mt-2 text-center">
                                        <div className="row">
                                            <div className="col-sm-4 col-md-4 col-12"><p className='fw-bold text-start m-0' style={{ color: this.props.customDetails?.headingColor, fontFamily: this.props.customDetails?.fontFamily }}>{this.capitalize(this.state?.consentDetails?.fetchType)}</p></div>
                                            <div className="col-sm-12 col-md-8 col-12"><p className="mb-4 text-start" style={{ color: this.props.customDetails?.textColor, paddingLeft: 0, fontFamily: this.props.customDetails?.fontFamily }}>{this.state?.consentDetails?.fetchType === 'ONETIME' ? 'Information fetch one time' : `Information fetch up to ${this.state?.consentDetails?.Frequency?.value} times a ${this.state?.consentDetails?.Frequency?.unit}`}</p></div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-12 col-6 mt-2">
                                        <div className="row">
                                            <div className="col-sm-4 col-md-4 col-12"><p className='fw-bold text-start m-0' style={{ color: this.props.customDetails?.headingColor, fontFamily: this.props.customDetails?.fontFamily }}>From Date</p></div>
                                            <div className="col-sm-12 col-md-8 col-12"><p className="mb-4 text-start" style={{ color: this.props.customDetails?.textColor, fontFamily: this.props.customDetails?.fontFamily }}>{this.dateFormat(this.state.consentDetails?.DataDateTimeRange?.from, false)}</p></div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-12 col-6 mt-2">
                                        <div className="row">
                                            <div className="col-sm-4 col-md-4 col-12"><p className='fw-bold text-start m-0' style={{ color: this.props.customDetails?.headingColor, fontFamily: this.props.customDetails?.fontFamily }}>To Date</p></div>
                                            <div className="col-sm-12 col-md-8 col-12"><p className="mb-4 text-start" style={{ color: this.props.customDetails?.textColor, fontFamily: this.props.customDetails?.fontFamily }}>{this.dateFormat(this.state.consentDetails?.DataDateTimeRange?.to, false)}</p></div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-12 col-12 mt-2">
                                        <div className="row">
                                            <div className="col-sm-4 col-md-4 col-12"><p className='fw-bold text-start m-0' style={{ color: this.props.customDetails?.headingColor, fontFamily: this.props.customDetails?.fontFamily }}>Account Information</p></div>
                                            <div className="col-sm-12 col-md-8 col-12"><p className="mb-4 text-start" style={{ color: this.props.customDetails?.textColor, fontFamily: this.props.customDetails?.fontFamily }}>{this.state.consentDetails?.consentTypes.map((el, i) => { return (<span className='mr-2' key={i}>{this.capitalize(el).replace(/[-_]/g, ' ')}{`${(this.state.consentDetails?.consentTypes?.length - 1) === i ? '' : ','}`}</span>) })}</p></div>
                                        </div>
                                    </div>


                                    <div className="col-lg-6 col-md-12 col-6 mt-2">
                                        <div className="row">
                                            <div className="col-sm-4 col-md-4 col-12"><p className='fw-bold text-start m-0' style={{ color: this.props.customDetails?.headingColor, fontFamily: this.props.customDetails?.fontFamily }}>Data {this.capitalize(this.state.consentDetails?.mode)}</p></div>
                                            <div className="col-sm-12 col-md-8 col-12"><p className="mb-4 text-start" style={{ color: this.props.customDetails?.textColor, fontFamily: this.props.customDetails?.fontFamily }}>Until {this.state.consentDetails?.DataLife?.value} {this.state.consentDetails?.DataLife?.unit}</p></div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-12 col-6 mt-2">
                                        <div className="row">
                                            <div className="col-sm-4 col-md-4 col-12"><p className='fw-bold text-start m-0' style={{ color: this.props.customDetails?.headingColor, fontFamily: this.props.customDetails?.fontFamily }}>Consent Expiry</p></div>
                                            <div className="col-sm-12 col-md-8 col-12"><p className="mb-4 text-start" style={{ color: this.props.customDetails?.textColor, fontFamily: this.props.customDetails?.fontFamily }}>{this.dateFormat(this.state.consentDetails?.expireTime, false)}</p></div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-12 col-12 mt-2 text-center">
                                        <div className="row">
                                            <div className="col-sm-4 col-md-4 col-12 fw-bold text-start m-0"><p className='fw-bold text-start m-0' style={{ color: this.props.customDetails?.headingColor, fontFamily: this.props.customDetails?.fontFamily }}>Account Types</p></div>
                                            <div className="col-sm-12 col-md-8 col-12 "><p className="mb-4 text-start ps-0" style={{ color: this.props.customDetails?.textColor, fontFamily: this.props.customDetails?.fontFamily }}>{this.state.consentDetails?.fiTypes.map((el, i) => { return (<span className='mr-2' key={i}>{this.capitalize(el).replace(/[-_]/g, ' ')}{`${(this.state.consentDetails?.fiTypes?.length - 1) === i ? '' : ','}`}</span>) })}</p></div>
                                        </div>
                                    </div>

                                    <div className="col-12 mt-2">
                                        <button type="button" className="btn btn-lg btn-block fs-16 fw-600 text-white" style={{ height: this.props.customDetails?.btnHeight, width: this.props.customDetails?.btnWidth, borderRadius: this.props.customDetails?.borderRadius, backgroundColor: this.props.customDetails?.primaryColor, fontFamily: this.props.customDetails?.fontFamily }} onClick={() => { this.setState({ consentDetails: null }) }}>BACK</button>
                                    </div>

                                </div>
                            </div>
                        </div>

                    }

                </div>
                {
                    !this.state.consentDetails ?
                        <div >
                            <div className='accept-row' style={{ width: '100%' }}>
                                <div className="pt-4 pb-2">
                                    <div className='container'>
                                        <p className='fs-14 fw-500 text-start px-4' style={{ color: this.props.customDetails?.textColor, fontFamily: this.props.customDetails?.fontFamily }}>Select your accounts for above institutions to fetch account information</p>
                                    </div>
                                    <button type="button" className="btn btn-lg fs-16 fw-600 text-white" onClick={this.selectAccounts} disabled={!this.state.selectedAccounts?.length} style={{ height: this.props.customDetails?.btnHeight, width: this.props.customDetails?.btnWidth, borderRadius: this.props.customDetails?.borderRadius, backgroundColor: this.props.customDetails?.primaryColor, fontFamily: this.props.customDetails?.fontFamily }}>
                                        Select Accounts
                                    </button>
                                </div>
                            </div>
                        </div>
                        : null
                }

            </div>
        )
    }
}
