import React, { Component } from 'react';
import AuthApis from '../hepler/authApis.service';
import { toast } from 'material-react-toastify';
import { postMessage } from '../utils/common.utils';
import Sheet from 'react-modal-sheet';

export default class NoAccountFound extends Component {
	constructor() {
		super();
		const queryParams = new URLSearchParams(window.location.search);
		this.isInitial = queryParams.get('isInitial') === 'true' ? true : false;
		this.state = {
			time: 60,
			do: false,
			complete: false,
			isResend: true,
			noAccount: queryParams.get('step')
				? parseInt(queryParams.get('step'))
				: 100,
			newMobileNumber: '',
			OTP: '',
			selectedNumber: this.props?.match?.params?.number
				? this.props.match.params?.number
				: global.mobileNumberArray[0],
		};
		this.fip = queryParams.get('fip');
		this.authApis = new AuthApis();
		console.log(
			this.isInitial,
			queryParams.get('isInitial'),
			this.fip,
			'isinitial'
		);
	}
	initiate = () => {
		// if (this.state.time !== 0) {
		this.setState((prevState, prevProps) => ({
			time: prevState.time - 1,
		}));
		if (this.state.time === 0) {
			clearInterval(this.id);
			this.setState({ complete: true, time: 60, isResend: true });
		}
		// }
	};
	sendOtp = () => {
		if (this.state.selectedNumber !== 'other') {
			if (this.fip) {
				this.props.history.push(
					`/accounts/${this.state.selectedNumber}/${this.fip}?isInitial=${this.isInitial}`
				);
			} else {
				if (this.isInitial) {
					this.props.history.push('/payment-consent');
				} else {
					this.props.history.push(
						`/home/?number=${this.state.selectedNumber}&isAddAccount=${true}`
					);
				}
			}
		} else {
			this.getOtp();
			this.props.updateLoader(true);
			this.authApis.mobileVerifyReq(this.state.newMobileNumber).then((res) => {
				this.props.updateLoader(false);
				console.log('rrrr - ', res);
				if (res.status === 'SEND') {
					this.setState({ noAccount: 102 });
				} else {
					toast.error(res);
					console.log(res);
					if (res.status === 'FAILURE') {
						postMessage('session', 'FAILURE');
					} else if (res.status === 'ERROR') {
						postMessage('session', 'ERROR');
					}
				}
			});
		}
	};
	useExistingNumber = (number) => {
		global.mobileNumber = number;
		this.props.history.push(`/home/?number=${number}&isAddAccount=${true}`);
	};
	verifyOtp = () => {
		this.props.updateLoader(true);
		this.authApis
			.varifyMobile(this.state.newMobileNumber, this.state.OTP)
			.then((res) => {
				console.log(res);
				this.props.updateLoader(false);
				if (res.status === 'ACCEPT') {
					global.mobileNumberArray.push(this.state.newMobileNumber);
					global.mobileNumber = this.state.newMobileNumber;
					if (this.fip) {
						this.props.history.push(
							`/accounts/${this.state.newMobileNumber}/${this.fip}?isInitial=${this.isInitial}`
						);
					} else {
						if (this.isInitial) {
							this.props.history.push('/payment-consent');
						} else {
							this.props.history.push(
								`/home/?number=${
									this.state.newMobileNumber
								}&isAddAccount=${true}`
							);
						}
					}
				} else {
					toast.error(res);
					console.log(res);
					clearInterval(this.id);
					this.setState({ complete: true, time: 60, isResend: true, OTP: '' });
					if (res.status === 'FAILURE') {
						postMessage('session', 'FAILURE');
					} else if (res.status === 'ERROR') {
						postMessage('session', 'ERROR');
					}
				}
			});
	};
	getOtp = () => {
		this.setState({ isResend: false, OTP: '' });
		this.id = setInterval(this.initiate, 1000);
	};
	selectNumber = (number) => {
		global.mobileNumber = number;
		this.setState({ selectedNumber: number });
	};
	goToHome = () => {
		global.mobileNumber = this.props.match.params?.number;
		if (this.isInitial) {
			this.props.history.push(`/payment-consent`);
		} else {
			this.props.history.push(
				`/home/?number=${this.props.match.params?.number}&isAddAccount=${true}`
			);
		}
	};

	handleOTPChange = (e) => {
		const regex = /^$|^[A-Z0-9]+$/;
		const inputValue = e.target.value.toUpperCase();
		if (regex.test(inputValue)) {
			this.setState({ OTP: inputValue });
		}
	};

	render() {
		return (
			<div className="no-account-found mt-9">
				<div className="container">
					<div className="row">
						{this.state.noAccount === 100 && (
							<div className="col-12 mt-5">
								<h5 style={{ textAlign: 'left', fontWeight: 600 }}>
									NO ACCOUNTS FOUND
								</h5>
								<div className="row py-5 px-3">
									<div className="col-lg-12 col-12">
										<h1
											className="fw-600 text-center"
											style={{
												color: this.props.customDetails?.headingColor,
												fontFamily: this.props.customDetails?.fontFamily,
											}}
										>
											Could not find accounts
										</h1>
										<p
											className="fs-14 fw-500 text-start"
											style={{
												color: this.props.customDetails?.textColor,
												fontFamily: this.props.customDetails?.fontFamily,
											}}
										>
											We could not find any accounts with your mobile no.{' '}
											<span
												className="fs-16 fw-600"
												style={{
													color: this.props.customDetails?.subHeadingColor,
												}}
											>
												{' '}
												{this.props.match.params.number}{' '}
											</span>
										</p>
										<p
											className="fs-15 fw-500 text-start"
											style={{
												color: this.props.customDetails?.headingColor,
												fontFamily: this.props.customDetails?.fontFamily,
											}}
										>
											This could be due to one of the following reasons:
										</p>
										<ul>
											<li
												className="fs-15 fw-500 text-start"
												style={{
													color: this.props.customDetails?.headingColor,
													fontFamily: this.props.customDetails?.fontFamily,
												}}
											>
												Your financial information provider could not discover
												any accounts of the specific type that your financial
												information user is seeking your consent for.
											</li>
											<li
												className="fs-15 fw-500 text-start"
												style={{
													color: this.props.customDetails?.headingColor,
													fontFamily: this.props.customDetails?.fontFamily,
												}}
											>
												You have a joint account - financial information
												providers only support discovery of singly held
												accounts, as of now.
											</li>
											<li
												className="fs-15 fw-500 text-start"
												style={{
													color: this.props.customDetails?.headingColor,
													fontFamily: this.props.customDetails?.fontFamily,
												}}
											>
												Your financial information provider is experiencing some
												technical difficulties - you may try again after some
												time.
											</li>
										</ul>
									</div>

									<div className="col-lg-3 col-md-6 col-12 mt-5">
										{this.props.customDetails?.sdkOptions
											?.allowDifferentMobile === 'Y' ? (
											<button
												type="button"
												className="btn px-5 text-white fs-16 fw-600"
												style={{
													height: this.props.customDetails?.btnHeight,
													width: this.props.customDetails?.btnWidth,
													borderRadius: this.props.customDetails?.borderRadius,
													backgroundColor:
														this.props.customDetails?.primaryColor,
												}}
												onClick={() => {
													this.setState({ noAccount: 101 });
												}}
											>
												Use Another Number
											</button>
										) : null}
										<button
											type="button"
											className="btn btn-lg fs-14 w-100 fw-600 pt-3"
											onClick={this.goToHome}
											style={{
												color: this.props.customDetails?.subHeadingColor,
												fontFamily: this.props.customDetails?.fontFamily,
											}}
										>
											Okay
										</button>
									</div>
								</div>
							</div>
						)}

						{this.state.noAccount === 101 && (
							<div className="col-12 mt-5">
								<h5
									style={{
										color: this.props.customDetails?.headingColor,
										fontFamily: this.props.customDetails?.fontFamily,
										textAlign: 'left',
										fontWeight: 600,
									}}
								>
									SELECT MOBILE NUMBER
								</h5>
								<div className="row py-5 px-3">
									<div className="col-lg-12 col-12">
										<p
											className="fs-16 fw-bold text-start"
											style={{
												color: this.props.customDetails?.textColor,
												fontFamily: this.props.customDetails?.fontFamily,
											}}
										>
											Select an already verified number for finding your
											accounts or use another number
										</p>
									</div>
									<div className="col-lg-12 col-12">
										<ul
											style={{
												textAlign: 'left',
												listStyle: 'none',
												padding: 0,
											}}
										>
											{global.mobileNumberArray.map((el, i) => {
												return (
													<li key={i}>
														<div
															className="form-check form-check-inline d-flex"
															style={{ alignItems: 'center' }}
														>
															<span
																style={{ cursor: 'pointer' }}
																className={`form-check-input-sm ${
																	this.state.selectedNumber === el
																		? 'selected-circle border-0'
																		: 'border-circle'
																} `}
																onClick={() => {
																	this.selectNumber(el);
																}}
															></span>
															<span
																style={{
																	marginLeft: '8px',
																	color: '#1168B4',
																	fontWeight: 600,
																}}
															>
																{' '}
																{el}
															</span>
														</div>
													</li>
												);
											})}
											<li>
												<div
													className="form-check form-check-inline d-flex"
													style={{ alignItems: 'center' }}
												>
													<span
														style={{ cursor: 'pointer' }}
														className={`form-check-input-sm ${
															this.state.selectedNumber === 'other'
																? 'selected-circle border-0'
																: 'border-circle'
														} `}
														onClick={() => {
															this.selectNumber('other');
														}}
													></span>
													<span
														style={{
															color: this.props.customDetails?.textColor,
															fontFamily: this.props.customDetails?.fontFamily,
															width: '100%',
															marginLeft: '8px',
														}}
													>
														{' '}
														Enter another number
													</span>
												</div>
												<div
													className="input-group mb-3 mt-2"
													style={{ paddingLeft: '30px', paddingRight: '25px' }}
												>
													<p
														style={{
															color: this.props.customDetails?.textColor,
															fontFamily: this.props.customDetails?.fontFamily,
															width: '100%',
														}}
													>
														OTP will be sent for verification
													</p>
													<input
														type="number"
														inputMode="decimal"
														disabled={this.state.selectedNumber !== 'other'}
														value={this.state.newMobileNumber}
														onChange={(e) => {
															this.setState({
																newMobileNumber: e.target.value,
															});
														}}
														className="form-control ps-3"
														placeholder="Enter Mobile No."
														aria-label="number"
														aria-describedby="basic-addon1"
														style={{
															fontFamily: this.props.customDetails?.fontFamily,
															minHeight: '50px',
														}}
													/>
												</div>
											</li>
										</ul>
									</div>
									{/* <div className='col-lg-5 col-md-6 col-12 mb-4'>
                                        <div className="input-group mb-3" style={{ height: '50px' }}>
                                            <input type="number" inputMode="decimal" value={this.state.newMobileNumber} onChange={(e) => { this.setState({ newMobileNumber: e.target.value }) }} className="form-control ps-3" placeholder="Enter Mobile No." aria-label="number" aria-describedby="basic-addon1" style={{ fontFamily: this.props.customDetails?.fontFamily }} />
                                        </div>
                                    </div> */}
									<div className="col-lg-3 col-md-6 col-12 d-flex justify-content-center mt-3">
										<button
											type="button"
											disabled={
												this.state.selectedNumber === 'other' &&
												(this.state.newMobileNumber?.length < 10 ||
													this.state.newMobileNumber?.length > 11)
											}
											className="btn float-end px-5 fw-600 fs-16 text-white"
											style={{
												height: this.props.customDetails?.btnHeight,
												width: this.props.customDetails?.btnWidth,
												borderRadius: this.props.customDetails?.borderRadius,
												backgroundColor: this.props.customDetails?.primaryColor,
												fontFamily: this.props.customDetails?.fontFamily,
											}}
											onClick={this.sendOtp}
										>
											CONTINUE
										</button>
									</div>
									<div className="col-lg-3 col-md-6 col-12 d-flex justify-content-center mt-4">
										<p
											className="fs-16 fw-bold text-start m-0"
											style={{
												color: '#1168B4',
												cursor: 'pointer',
												fontFamily: this.props.customDetails?.fontFamily,
											}}
											onClick={this.goToHome}
										>
											BACK TO INSTITUTIONS
										</p>
									</div>
								</div>
								{/* {
                                    (global.mobileNumberArray?.length > 1) ?
                                        <div className='row'>
                                            <div className='col-12'>
                                                <p style={{ color: this.props.customDetails?.textColor, fontFamily: this.props.customDetails?.fontFamily }}>OR use below number</p>
                                            </div>
                                        </div> : null
                                }
                                {
                                    (global.mobileNumberArray?.length > 1) ?
                                        <div className='row px-3'>
                                            <div className='col-lg-12 col-12'>
                                                <ul style={{ textAlign: 'left', listStyle: 'none', padding: 0 }}>
                                                    {
                                                        global.mobileNumberArray.map((el, i) => {
                                                            return (
                                                                <li key={i} onClick={() => { this.useExistingNumber(el) }}>
                                                                    <span className='bleu-text-color' style={{ color: this.props.customDetails?.textColor, fontFamily: this.props.customDetails?.fontFamily, textUnderlineOffset: '4px' }}><u>{el}</u></span>
                                                                </li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                        </div> : null
                                } */}
							</div>
						)}
					</div>
				</div>
				<Sheet
					snapPoints={[400]}
					disableDrag={true}
					isOpen={this.state.noAccount === 102}
					onClose={() => this.setState({ noAccount: 100 })}
				>
					<Sheet.Container>
						<Sheet.Content>
							<div className="row">
								<div className="col-12 mt-5">
									<div className="row py-5 px-3">
										<div className="col-lg-12 col-12">
											<p
												className="fs-16 fw-bold text-start"
												style={{
													color: this.props.customDetails?.textColor,
													fontFamily: this.props.customDetails?.fontFamily,
												}}
											>
												Enter OTP as received on {this.state.newMobileNumber}
											</p>
										</div>
										<div className="col-lg-5 col-md-6 col-12 mb-4">
											<div
												className="input-group mb-3"
												style={{ height: '50px' }}
											>
												<input
													type="text"
													value={this.state.OTP}
													pattern="^$|^[A-Z0-9]+$"
													title="Capital letters and numbers only"
													onChange={this.handleOTPChange}
													className="form-control ps-3"
													placeholder="Enter OTP"
													aria-label="otp"
													aria-describedby="basic-addon1"
													style={{
														fontFamily: this.props.customDetails?.fontFamily,
													}}
												/>
											</div>
											<div className="d-flex justify-content-between align-items-center">
												<button
													className="fs-14 fw-600 link-btn"
													disabled={!this.state.isResend}
													onClick={this.sendOtp}
													style={{
														color: this.state.isResend
															? this.props.customDetails?.subHeadingColor
															: 'grey',
														fontFamily: this.props.customDetails?.fontFamily,
													}}
												>
													Resend OTP
												</button>
												{this.state.time !== 60 ? (
													<p
														className="fs-14 fw-600 text-success my-auto"
														style={{
															fontFamily: this.props.customDetails?.fontFamily,
														}}
													>
														in {this.state.time} secs
													</p>
												) : null}
											</div>
										</div>
										<div className="col-lg-3 col-md-6 col-12 d-flex justify-content-center">
											<button
												disabled={
													!(
														this.state.OTP?.length === 6 ||
														this.state.OTP?.length === 8
													)
												}
												type="button"
												className="btn float-end px-5 fw-600 fs-16 text-white"
												style={{
													height: this.props.customDetails?.btnHeight,
													width: this.props.customDetails?.btnWidth,
													borderRadius: this.props.customDetails?.borderRadius,
													backgroundColor:
														this.props.customDetails?.primaryColor,
													fontFamily: this.props.customDetails?.fontFamily,
												}}
												onClick={this.verifyOtp}
											>
												VERIFY
											</button>
										</div>
									</div>
								</div>
							</div>
						</Sheet.Content>
					</Sheet.Container>

					<Sheet.Backdrop />
				</Sheet>
			</div>
		);
	}
}
